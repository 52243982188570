// Constants for Hashnode blog
const HASHNODE_USERNAME = "helloworldnadia";
const HASHNODE_BLOG_URL = "https://n-coding.hashnode.dev";
const PROXY_ENDPOINT = "/.netlify/functions/hashnode-proxy";

// Import dependencies
import initScrollReveal from "./scripts/scrollReveal";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";
import VanillaTilt from "vanilla-tilt";

// Hashnode GraphQL query
const QUERY = `
  query GetUserPosts($username: String!) {
    user(username: $username) {
      publications(first: 1) {
        edges {
          node {
            posts(first: 7) {
              edges {
                node {
                  id
                  title
                  slug
                  publishedAt
                  coverImage {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// GraphQL client function
async function gql(query, variables = {}) {
  try {
    const response = await fetch(PROXY_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query, variables }),
    });

    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const { data, errors } = await response.json();
    if (errors) throw new Error(errors[0].message);

    return data;
  } catch (error) {
    console.error("GraphQL request failed:", error);
    throw error;
  }
}

// Helper: Create element with attributes and classes
function createElement(tag, { attributes = {}, classes = [], text = "" } = {}) {
  const el = document.createElement(tag);
  Object.entries(attributes).forEach(([key, value]) =>
    el.setAttribute(key, value)
  );
  if (classes.length) el.classList.add(...classes);
  if (text) el.textContent = text;
  return el;
}

// Create post card element
function createPostCard(post) {
  const { title, slug, publishedAt, coverImage } = post;

  const card = createElement("div", {
    classes: ["card", "rounded", "h-100", "js-tilt"],
    attributes: {
      "data-tilt": "",
      "data-tilt-max": "4",
      "data-tilt-glare": "true",
      "data-tilt-max-glare": "0.5",
    },
  });

  // Image setup with fallback
  const img = createElement("img", {
    attributes: {
      src: coverImage?.url || "/src/assets/images/default-image.jpg", // Default image path
      alt: `Cover image for ${title}`,
    },
  });

  card.appendChild(img);

  // Description setup
  const desc = createElement("div", { classes: ["desc"] });
  desc.appendChild(createElement("h3", { text: title || "Untitled Post" }));

  if (publishedAt) {
    const dateText = new Date(publishedAt).toLocaleDateString();
    desc.appendChild(
      createElement("p", {
        classes: ["post-date"],
        text: `Published on: ${dateText}`,
      })
    );
  }

  card.appendChild(desc);

  // Link wrapper
  const link = createElement("a", {
    classes: ["col", "d-flex", "blog-wrapper__card", "load-hidden"],
    attributes: {
      href: slug ? `${HASHNODE_BLOG_URL}/${slug}` : "#",
      target: "_blank",
      rel: "noopener noreferrer",
    },
  });
  link.appendChild(card);

  return link;
}

// Create "Discover more" card
function createReadMoreCard() {
  // Create main card wrapper
  const card = createElement("div", {
    classes: ["card", "read-more-card", "rounded", "h-100", "w-100", "js-tilt"],
    attributes: {
      "data-tilt": "",
      "data-tilt-max": "4",
      "data-tilt-glare": "true",
      "data-tilt-max-glare": "0.5",
    },
  });

  // Add arrow container
  const arrowContainer = createElement("div", { classes: ["arrow-container"] });
  arrowContainer.innerHTML = `
    <svg class="arrow-right" viewBox="0 0 24 24" width="64" height="64" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <polyline points="12 5 19 12 12 19"></polyline>
    </svg>
  `;
  card.appendChild(arrowContainer);

  // Add description div
  const desc = createElement("div", { classes: ["desc"] });
  desc.appendChild(createElement("h3", { text: "Discover more blog posts" }));
  card.appendChild(desc);

  // Create the anchor wrapper
  const link = createElement("a", {
    classes: ["col", "d-flex", "blog-wrapper__card", "load-hidden"],
    attributes: {
      href: HASHNODE_BLOG_URL,
      target: "_blank",
      rel: "noopener noreferrer",
    },
  });
  link.appendChild(card);

  return link;
}

// Initialize tilt effect
function initTiltOnElements() {
  VanillaTilt.init(document.querySelectorAll(".js-tilt"));
}

// Fetch and render posts
async function fetchAndRenderPosts() {
  const blogPosts = document.getElementById("blogPosts");
  if (!blogPosts) {
    console.error("Blog posts container not found!");
    return;
  }

  blogPosts.innerHTML = "<p>Loading blog posts...</p>";

  try {
    const data = await gql(QUERY, { username: HASHNODE_USERNAME });
    const posts =
      data?.user?.publications?.edges[0]?.node?.posts?.edges.map(
        (edge) => edge.node
      ) || [];

    if (posts.length === 0) {
      blogPosts.innerHTML = "<p>No blog posts found.</p>";
      return;
    }

    const fragment = document.createDocumentFragment();
    posts.forEach((post) => {
      try {
        const postElement = createPostCard(post);
        fragment.appendChild(postElement);
      } catch (error) {
        console.error("Error creating post card:", error);
      }
    });

    const readMoreCard = createReadMoreCard();
    fragment.appendChild(readMoreCard);

    blogPosts.innerHTML = "";
    blogPosts.appendChild(fragment);

    initTiltOnElements();
    initScrollReveal(targetElements, defaultProps);
  } catch (error) {
    console.error("Error fetching blog posts:", error);
    blogPosts.innerHTML = `
      <div class="error-message">
        <p>Failed to load blog posts. Please try again later.</p>
        <p class="error-details">${error.message}</p>
      </div>
    `;
  }
}

// Navigation toggle
function toggleMenu() {
  const nav = document.querySelector(".Navbar__Items");
  const icon = document.querySelector(".Navbar__Link-toggle i");
  nav.classList.toggle("Navbar__ToggleShow");
  icon?.classList.toggle(
    "toggle",
    nav.classList.contains("Navbar__ToggleShow")
  );
}

// Initialize everything
document.addEventListener("DOMContentLoaded", () => {
  fetchAndRenderPosts();
  initScrollReveal(targetElements, defaultProps);
  document
    .querySelector(".Navbar__Link-toggle")
    .addEventListener("click", toggleMenu);
});
